// @ts-nocheck
import Service, { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

import type { CashflowCategoryAssignment } from 'qonto/react/models/cash-flow-category';
import type { LabelCategorizationRule } from 'qonto/react/models/label';
import {
  apiBaseURL,
  cashFlowCategoriesNamespace,
  cashFlowCategoriesNamespaceV2,
} from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { FilterConditional, FilterExpressionOperator } from 'qonto/react/models/filters';
import type { MemorizeCategoriesRule } from 'qonto/react/models/memorize-category';

export default class CategorizationRulesManager extends Service {
  @service networkManager;
  @service organizationManager;
  @service intl;
  @service toastFlashMessages;
  @service sentry;
  @service store;

  onCreateCashFlowCategoryRulesTask = dropTask(async (rules: MemorizeCategoriesRule[]) => {
    const payload = rules.map(rule => {
      return {
        category_id: rule.categoryId,
        matching_criteria: {
          raw_counterparty_name: rule.matchingCriteria.rawCounterpartyName,
          counterparty_name: rule.matchingCriteria.counterpartyName,
        },
        include_past: rule.includePast,
      };
    });

    try {
      await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespaceV2}/cash-flow/categorization-rules`,
        {
          method: 'POST',
          data: JSON.stringify({ data: payload }),
        }
      );
      this.toastFlashMessages.toastInfo(this._getCashFlowCategoryRulesCreationSuccessCopy(rules));
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.counterparty.failed'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  onCreateAnalyticLabelRulesTask = dropTask(async (rules: LabelCategorizationRule[]) => {
    const payload = rules.map(rule => {
      return {
        label_list_id: rule.labelListId,
        label_id: rule.labelId || null,
        matching_criteria: {
          raw_counterparty_name: rule.matchingCriteria.rawCounterpartyName,
          counterparty_name: rule.matchingCriteria.counterpartyName,
        },
        include_past: rule.includePast,
      };
    });

    try {
      await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespace}/cash-flow/categorization-rules`,
        {
          method: 'POST',
          data: JSON.stringify({ data: payload }),
        }
      );

      this.labelizeTransactionsTask.perform(rules);

      this.toastFlashMessages.toastInfo(this._getAnalyticLabelRulesCreationSuccessCopy(rules));
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.counterparty.failed'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  labelizeTransactionsTask = dropTask(
    waitFor(async (rules: LabelCategorizationRule[]) => {
      const query = this._prepareStoreSearchQuery(rules);
      if (!query) return;

      try {
        let { transactions, meta } = await this.store.adapterFor('transaction').search(query);
        let transactionIds = transactions.map(({ id }) => id);
        let labelIds = rules.map(rule => rule.labelId).filter(Boolean);

        let storeTransactions = transactionIds.map(id => this.store.peekRecord('transaction', id));
        let labels = labelIds.map(id => this.store.peekRecord('label', id));

        storeTransactions.forEach(transaction => transaction.set('labels', labels));
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    })
  );

  categorizeTransactions = ({ transactionIds, categoryId }: CashflowCategoryAssignment) => {
    let storeTransactions = transactionIds.map(id => this.store.peekRecord('transaction', id));
    storeTransactions.forEach(transaction => {
      if (categoryId === null) {
        transaction.set('categoryAssignment', null);
      } else {
        transaction.set('categoryAssignment', { id: categoryId });
      }
    });
  };

  _getAnalyticLabelRulesCreationSuccessCopy(rules: LabelCategorizationRule[]) {
    const counterparties = new Set(rules.map(rule => rule.matchingCriteria.rawCounterpartyName));
    const labels = new Set(rules.map(rule => rule.labelId));
    const labelLists = new Set(rules.map(rule => rule.labelListId));
    const labelCount = Math.max(labels.size, labelLists.size);

    const hasManyCounterparties = counterparties.size > 1;
    const includePast = rules[0].includePast;
    const counterpartyQuantity = this.intl.t(
      'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.toast.counterparty-placeholder',
      { count: counterparties.size }
    );
    const singleKeyFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.toast.success.future.single',
      {
        count: labelCount,
      }
    );
    const singleKeyPastFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.toast.success.past-future.single',
      {
        count: labelCount,
      }
    );
    const bulkKeyFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.toast.success.future.bulk',
      {
        count: labelCount,
        counterparty_quantity: counterpartyQuantity,
      }
    );
    const bulkKeyPastFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.analytic-labels.auto-labelling-widget.toast.success.past-future.bulk',
      {
        count: labelCount,
        counterparty_quantity: counterpartyQuantity,
      }
    );
    return this._getSuccessCopy(includePast, hasManyCounterparties, {
      singleFuture: singleKeyFuture,
      singlePastFuture: singleKeyPastFuture,
      bulkFuture: bulkKeyFuture,
      bulkPastFuture: bulkKeyPastFuture,
    });
  }

  _getCashFlowCategoryRulesCreationSuccessCopy(rules: MemorizeCategoriesRule[]) {
    const counterparties = new Set(rules.map(rule => rule.matchingCriteria.rawCounterpartyName));
    const hasManyCounterparties = counterparties.size > 1;
    const includePast = rules[0].includePast;

    const counterpartyQuantity = this.intl.t(
      'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.toast.counterparty-placeholder',
      { count: counterparties.size }
    );
    const singleKeyFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.toast.success.future.single'
    );
    const singleKeyPastFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.toast.success.past-future.single'
    );
    const bulkKeyFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.toast.success.future.bulk',
      {
        counterparty_quantity: counterpartyQuantity,
      }
    );
    const bulkKeyPastFuture = this.intl.t(
      'transactions.sidepanel.cash-flow.categories.auto-categorize-widget.toast.success.past-future.bulk',
      {
        counterparty_quantity: counterpartyQuantity,
      }
    );
    return this._getSuccessCopy(includePast, hasManyCounterparties, {
      singleFuture: singleKeyFuture,
      singlePastFuture: singleKeyPastFuture,
      bulkFuture: bulkKeyFuture,
      bulkPastFuture: bulkKeyPastFuture,
    });
  }

  _getSuccessCopy(
    includePast: boolean,
    hasManyCounterparties: boolean,
    keys: {
      singleFuture: string;
      singlePastFuture: string;
      bulkFuture: string;
      bulkPastFuture: string;
    }
  ) {
    const categorizationSuccessSingle = includePast ? keys.singlePastFuture : keys.singleFuture;
    const categorizationSuccessBulk = includePast ? keys.bulkPastFuture : keys.bulkFuture;
    return hasManyCounterparties ? categorizationSuccessBulk : categorizationSuccessSingle;
  }

  _prepareStoreSearchQuery(rules: LabelCategorizationRule[]) {
    const applicableRules = rules.filter(rule => rule.includePast);

    if (!applicableRules.length) return null;

    const counterpartyNames = new Set(
      applicableRules.map(rule => rule.matchingCriteria.counterpartyName)
    );
    const rawCounterpartyNames = new Set(
      applicableRules.map(rule => rule.matchingCriteria.rawCounterpartyName)
    );
    const [property, direction] = DEFAULT_SORT_BY.split(':');

    return {
      sort: { property, direction },
      pagination: { page: 1, per_page: 500 },
      filter_group: {
        conditional: FilterConditional.Or,
        expressions: [
          {
            property: 'display_name',
            operator: FilterExpressionOperator.Eq,
            values: [...counterpartyNames, ...rawCounterpartyNames],
          },
        ],
      },
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'categorization-rules-manager': CategorizationRulesManager;
  }
}
